// == TIMER == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// timer
.section--timer {
  @include boxShadow;
  @include borderRadius(0, 0, 5, 5);
  text-align: center;

  .section__title {
    margin: 0 0 rem-calc(8);
  }

  .eyebrow {
    text-transform: uppercase;
    font-size: rem-calc(12);
    margin-bottom: rem-calc(18);
  }
}

// progress bar
.progress-bar {
  width: 100%;
  height: rem-calc(8);
  background: $color-grey-light;
  border-radius: rem-calc(4);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);

  &__tracker {
    height: 100%;
    border-radius: rem-calc(3.5);
  }
}
