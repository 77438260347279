// == COLORS == //
// - Main - //
$color-primary : #EA433D;

// - Greys - //
$color-white : #FFFFFF;
$color-grey-lighter : #F4F4F4;
$color-grey-light : #EEEEEE;
$color-grey-medium : #8E8F90;
$color-grey-dark : #616263;
$color-black : #1E2021;

// - Alerts - //
$color-success : #00C853;
// $color-info : #2196F3;
$color-warning : #FFAC00;
$color-danger : #FF2500;

// - Counter - //
$counter-grey-lighter : #383838;
$counter-grey-light : #494949;
$color-success-darker : #12773C;  
