// == ORDER == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// order
.section--order {
  @include boxShadow;
  @include borderRadius(5, 5, 5, 5);

  .section__title {
    margin-top: rem-calc(6);
  }

  & > .eyebrow {
    color: $color-grey-medium;
  }

  .section__paragraph {
    margin: rem-calc(6) 0 rem-calc(24);
  }

  &__meals {
    margin: 0;
    padding: 0;
    list-style: none;
  
    li {
      border-top: 2px solid $color-grey-light;
  
      &:last-child {
        .meal {
          .eyebrow {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// page loader
.page-loader {
  position: absolute;
  width: rem-calc(40);
  height: rem-calc(40);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    border-top: rem-calc(2) solid $color-primary;
    border-right: rem-calc(2) solid $color-primary;
    border-bottom: rem-calc(2) solid transparent;
    border-radius: 50%;
    animation: 1s spin linear infinite;
  }
}
