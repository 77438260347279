// == FOOTER == //
// imports
@import '../../scss/base/util';

// footer
.footer {
  @include boxShadow;
  position: fixed;
  bottom: rem-calc(-66);
  width: 100%;
  transition: bottom .4s ease-in-out;

  &.container {
    padding: 0 rem-calc(16) rem-calc(20);
  }

  .button {
    margin-bottom: 0;
  }

  &--active {
    bottom: 0;
  }

  &-after {
    height: 0;
    transition: height .4s ease-in-out;

    &--active {
      height: rem-calc(66);
    }
  }
}

#rsg-root {
  .footer {
    position: relative;
    bottom: 0;

    &.container {
      padding: 0;
    }

    &-after {
      display: none;
    }
  }
}
