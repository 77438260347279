// == HEADER == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/typography';
@import '../../scss/base/colors';

// header
.header {
  @include boxShadow;
  position: fixed;
  width: 100%;
  background: $color-grey-lighter;
  height: rem-calc(60);
  display: flex;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;

  &__icon {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 1rem;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__title {
    @include font('bold');
    margin: 0 0 0 1rem;
    color: $color-black;
  }

  &__link {
    margin-left: auto;
  }

  &-after {
    height: rem-calc(60);
  }
}

#rsg-root {
  .header {
    position: relative;
    padding: 0 1rem;

    &-after {
      display: none;
    }
  }
}
