// == MEAL == //
// imports
@import '../../scss/base/util';

// meal
.meal {

  &__name {
    margin: rem-calc(12) 0 rem-calc(4);
  }

  .eyebrow {
    min-height: rem-calc(16);
    margin-bottom: 1rem;
  }
}
