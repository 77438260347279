// == UTILITIES == //
// - Functions - //
// rem-calc
@function rem-calc($value) {
  @return ($value / 16) + rem;
}

// - Mixins - //
// font
@mixin font($weight: 'regular') {
  @if ($weight == 'bold') {
    font-family: 'mulibold', sans-serif;
  }
  @else if ($weight == 'extrabold') {
    font-family: 'muliextrabold', sans-serif;
  }
  @else {
    font-family: 'muliregular', sans-serif;
  }
}

// box shadow
@mixin boxShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

// border radius
@mixin borderRadius($topleft, $topright, $bottomright, $bottomleft) {
  border-radius: rem-calc($topleft) rem-calc($topright) rem-calc($bottomright) rem-calc($bottomleft);
}
