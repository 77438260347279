// == RESTAURANT == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// restaurant
.section--restaurant {
  @include boxShadow;

  .section__title {
    margin-bottom: rem-calc(8);
  }

  .section__paragraph {
    margin-top: rem-calc(8);
    color: $color-grey-medium;
  }

  .button-group {
    margin-bottom: 1rem;
  }
}
