// == LINK == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// link
.link {
  @include font('extrabold');
  font-size: rem-calc(12);
  text-decoration: none;
  text-transform: uppercase;
  color: $color-primary;
}
