// == EYEBROW == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// eyebrow
.eyebrow {
  @include font();
  font-size: rem-calc(13);
  color: $color-grey-dark;
}
