// == BUTTON == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// animations
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    background: transparentize($counter-grey-light, .58);
  }
  5% {
    transform: translate(-50%, -50%) scale(1.2);
    background: transparentize($counter-grey-light, .3);
  }
  70% {
    transform: translate(-50%, -50%) scale(1);
    background: transparentize($counter-grey-light, .58);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    background: transparentize($counter-grey-light, .58);
  }
}
@keyframes bounce {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

// counter
.counter {
  width: rem-calc(156);
  height: rem-calc(156);
  border-radius: rem-calc(78);
  position: relative;
  margin-top: rem-calc(56);

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 8px 10px rgba(0, 0, 0, 0.2);
  }

  &__pulse {
    position: absolute;
    z-index: 0;
    width: rem-calc(198);
    height: rem-calc(198);
    border-radius: rem-calc(99);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    background: transparentize($counter-grey-light, .58);
    animation-name: pulse;
    animation-duration: 1s;
  }

  &__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;

    svg {
      transform: rotate(-90deg);
    }

    circle {
      stroke-width: 7;

      &.full {
        stroke: $counter-grey-light;
      }

      &.percentage {
        fill: none;
        stroke-dasharray: 270;
      }
    }
  }

  &__accepted {
    position: absolute;
    width: rem-calc(46);
    height: rem-calc(34);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 3;
    animation: bounce .5s forwards;

    svg {
      fill-rule:evenodd;
      clip-rule:evenodd;
      stroke-linejoin:round;
      stroke-miterlimit:2;

      .check {
        position: absolute;
        fill: $color-white;
      }
    }
  }

  &__seconds {
    position: absolute;
    z-index: 3;
    @include font('bold');
    font-size: rem-calc(38);
    top: rem-calc(40);
    left: 50%;
    transform: translateX(-50%);
  }

  &__text {
    position: absolute;
    z-index: 3;
    top: rem-calc(90);
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
}

// longpress buttons
button.longpress {

  @keyframes hold {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    50% {
      width: 100%;
      height: 100%;
    }
    100% {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  user-select: none;
  border: none;
  border-radius: rem-calc(49);
  padding: 0;
  width: rem-calc(98);
  height: rem-calc(98);
  background-color: $counter-grey-lighter;
  position: relative;
  border: 3px solid $counter-grey-light;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    width: rem-calc(20);
    height: rem-calc(20);
  }

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2rem;
    color: $color-white;
    @include font('extrabold');
    font-size: rem-calc(14);
    text-transform: uppercase;
    white-space: nowrap;
  }

  &:focus {
    outline: none;
  }

  &.longpress--decline {
    &:before {
      background-image: url('../../assets/discard-icon.png');
      background-size: rem-calc(20);
    }
  }

  &.longpress--accept {
    background-color: $color-success;
    border: 3px solid $color-success-darker;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.66);

    &:before {
      width: rem-calc(29);
      background-image: url('../../assets/accept-icon.png');
      background-size: rem-calc(20);
      background-size: rem-calc(29);
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $color-success-darker;
      border-radius: 50%;
      opacity: 0;
    }
  }
}

// click-n-hold
.cnh_holding {
  button.longpress {
    &.longpress--decline {
      border: 3px solid $color-primary;
    }
    &.longpress--accept {
      &:after {
        animation: hold 1s infinite;
      }
    }
  }
}
