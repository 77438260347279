// == BUTTON == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// button
.button {
  padding: 0 2rem;
  height: rem-calc(46);
  border-radius: rem-calc(29);
  line-height: rem-calc(44);
  @include font('extrabold');
  font-size: rem-calc(14);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: block;

  &--primary {
    background: $color-primary;
    color: $color-white;

    &.button--ghost {
      background: transparent;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  &--icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: rem-calc(12);
      height: rem-calc(16);
      margin-right: rem-calc(6);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    
    &.button--directions {
      &:before {
        background-image: url('../../assets/IconDirections.png');
      }
    }

    &.button--call {
      &:before {
        background-image: url('../../assets/IconCall.png');
      }
    }
  }
}

// button group
.button-group {
  display: flex;
  justify-content: space-between;

  .button,
  .longpress,
  & > div {
    flex-shrink: 0;
    flex-grow: 1;
    margin: 0 rem-calc(8);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .longpress,
  & > div {
    margin: 0 rem-calc(20);
  }
}
