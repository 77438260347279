// == GENERAL ==//
// imports
@import '../../node_modules/normalize.css/normalize.css';
@import './base/colors';
@import './base/util';
@import './base/typography';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $color-grey-light;
  height: 100vh;
  position: relative;
}

.container {
  padding: 0 1rem;

  &--full {
    padding: 1rem 1rem 1.5rem;
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    h1 {
      @include font('extrabold');
      font-size: rem-calc(24);
      margin-top: 2.5rem;
    }

    .button-group {
      margin: auto 0 2.5rem;
    }
  }

  &--dark {
    background: $color-black;
    color: $color-white;

    p {
      font-size: rem-calc(13);
      color: $color-grey-light;
      &.bold {
        @include font('bold');
      }
      &.loading {
        margin: auto 0 3rem;
      }
    }
  }
}

.section {
  background: $color-white;
  margin-bottom: rem-calc(22);
  padding: 1rem;

  &__title {
    @include font('bold');
    font-size: rem-calc(18);
    color: $color-black;

    &--icon {
      display: flex;

      .icon {
        @include borderRadius(2, 2, 2, 2);
        bottom: 0;
        display: block;
        font-size: 1rem;
        background: $color-grey-light;
        width: rem-calc(24);
        height: rem-calc(24);
        margin-right: rem-calc(14);
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        &--loading {

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: rem-calc(10);
            height: rem-calc(10);
            top: rem-calc(7);
            left: rem-calc(7);
            margin: 0;
            background: transparent;
            border-top: rem-calc(2) solid $color-primary;
            border-right: rem-calc(2) solid $color-primary;
            border-bottom: rem-calc(2) solid transparent;
            border-radius: 50%;
            animation: 1s spin linear infinite;
          }
        }

        &--success {
          background: $color-success;

          &:after {
            content: '';
            position: absolute;
            display: block;
            background: url('../assets/tick.svg');
            width: rem-calc(16);
            height: rem-calc(12);
            top: rem-calc(6);
            left: rem-calc(4);
          }
        }
      }

      span {
        line-height: rem-calc(22);
      }
    }
  }

  &__paragraph {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    color: $color-black;
  }
}
