// == TYPOGRAPHY == //
// - Fontface - //
@font-face {
  font-family: 'muliregular';
  src: url('../../assets/fonts/muli-webfont.woff2') format('woff2'),
       url('../../assets/fonts/muli-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mulibold';
  src: url('../../assets/fonts/muli-bold-webfont.woff2') format('woff2'),
       url('../../assets/fonts/muli-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'muliextrabold';
  src: url('../../assets/fonts/muli-extrabold-webfont.woff2') format('woff2'),
       url('../../assets/fonts/muli-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// - General - //
body {
  @include font();
}
